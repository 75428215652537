<script>
  import { project, translations } from "$lib/services/store";

  export let text = null;
  export let lang = $project?.settings?.lang ?? "en";
  export let lowercase = false;

  function translate(text, lang) {
    if ($translations[text]) {
      if (lowercase) {
        return $translations[text][lang].toLowerCase() ?? text.toLowerCase()
      } else {
        return $translations[text][lang] ?? text
      }
    }
    return text
  }

  let translated = translate(text, lang);
</script>

{translated}